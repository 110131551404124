import { IFeeds, ISukaiFeeds } from "Data/DataForum"
import hitungWaktu from "fungsi/hitungWaktu"
import { FC, MutableRefObject, useCallback, useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import firebase from "firebase"
import "./FeedsCard.scss"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"
import IkonLoveIsi from "./love-isi.svg"
import IkonLoveKosong from "./love-kosong.svg"
import IkonKomentar from "./komentar-feeds.svg"
import IkonCross from "./cross-hapus.svg"
import { Link, useHistory } from "react-router-dom"
import { terjemahkanWaktuNonton, waktuRealtime } from "fungsi/fungsiWaktu"
import { toast } from "react-toastify"
import { usePenggunaId } from "fungsi/usePengguna"

const FeedsCard: FC<IFeedsCard> = (p) => {
  const [auth] = useAuthState(firebase.auth())
  const [querySuka, setQuerySuka] = useState<firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined>(undefined)
  const sejarah = useHistory()
  useEffect(() => {
    if(auth === null || auth === undefined) return
    setQuerySuka(firebase.firestore().doc(`statusSukaiFeeds/${auth.uid}${p.feeds.idFeeds}`))
  }, [auth])
  const [dataSuka] = useDocumentDataOnce<ISukaiFeeds>(querySuka)

  const [suka, setSuka] = useState(false)
  const [sukaBaru, setSukaBaru] = useState(false)
  useEffect(() => {
    setSuka(dataSuka?.statusSukai === true)
  }, [dataSuka])

  const handelSukaiFeeds = async () => {
    if(auth === null || auth === undefined){
      sejarah.push(`/h/login`)
      return
    }
    if(querySuka === undefined || suka === true) return
    try{
      setSuka(true)
      setSukaBaru(true)
      const [waktuDapat] = await waktuRealtime()
      const sukaiFeeds: ISukaiFeeds = {
        idPengguna: auth.uid,
        idFeeds: p.feeds.idFeeds,
        idPos: auth.uid + p.feeds.idFeeds,
        idYangNgepos: p.feeds.idPengguna,
        statusSukai: true,
        waktuSukai: waktuDapat
      }
      await querySuka.set(sukaiFeeds)
      console.log("berhasil suka feeds")
    }catch(e){
      setSuka(true)
      setSukaBaru(true)
      toast.error("Kesalahan menyukai feeds")
      console.error(e)
    }
  }
  const penjumlahanSuka = (suka && sukaBaru) ? 1 : 0
  const [penggunaPos] = usePenggunaId(p.feeds.idPengguna)
  const [lihatSelengkapnyaAktif, setLihatSelengkapnyaAktif] = useState(false)
  const handelLihatSelengkapnya = () => setLihatSelengkapnyaAktif(!lihatSelengkapnyaAktif)
  
  return(
    <li className="feeds-card-apl-animemo" ref={p.indeks === p.indeksTerakhir ? p.refArTerakhir : undefined}>
      <div className="header-feeds">
        <img className="foto-profil" src={penggunaPos?.urlFotoProfilKecilPengguna || undefined} />
        <div className="info-pengguna">
          <h6 className="nama-pengguna">
            {p.feeds.namaPengguna} <span>{terjemahkanWaktuNonton(penggunaPos?.jumlahNontonMenit)} nonton</span>
          </h6>
          <div className="tanggal-pos">
            {hitungWaktu(p.feeds.tanggalUpload.seconds)}
          </div>
        </div>
        {(auth?.uid === p.feeds.idPengguna && p.beranda !== true) && <img className="tbl-hapus" 
        src={IkonCross}
        onClick={() =>( p.feeds.idFeeds && p.hapusFeeds) && p.hapusFeeds(p.feeds.idFeeds)}/>}
      </div>
      <div className="kontainer-gbr-feeds">
        <img className="isi-feeds" src={p.feeds.fotoFeeds || undefined}/>
      </div>
      <div className="aksi-bawah-feeds">
        <div className="aksi-feeds">
          <img className="suka-feeds gbr-aksi" src={suka ? IkonLoveIsi : IkonLoveKosong} onClick={handelSukaiFeeds}/>
          <Link to={`/h/komentar-feeds/${p.feeds.idFeeds}`} onClick={p.cacheLaman}><img className="gbr-aksi" src={IkonKomentar}/></Link>
        </div>
        <div className="suka-komentar-jumlah">
          {p.feeds.jmlSuka + penjumlahanSuka} suka, {p.feeds.jmlKomentar} komentar
        </div>
        <p className="nama-isi-feeds">
          <b>{p.feeds.namaPengguna}</b> {(lihatSelengkapnyaAktif ? p.feeds.isiFeeds : (p.feeds.isiFeeds?.substring(0, 160))) + 
          (((p.feeds.isiFeeds?.length || 0) > 160) && !lihatSelengkapnyaAktif ? '...' : '')}
        </p>
        {(p.feeds.isiFeeds?.length || 0) > 160 && <div className="lihat-selengkapnya" onClick={handelLihatSelengkapnya}>
          {!lihatSelengkapnyaAktif ? `lihat selengkapnya` : `lebih sedikit`}
        </div>}
      </div>
    </li>
  )
}

interface IFeedsCard{
  feeds: IFeeds
  beranda?: boolean
  hapusFeeds?: (idFeeds: string) => any
  indeks: number
  indeksTerakhir: number
  refArTerakhir?: MutableRefObject<HTMLLIElement | null>
  cacheLaman?: () => void
}

export default FeedsCard