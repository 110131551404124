import './Website.scss';
import '../navigasi/navigasiAtas/NavigasiAtas.scss';
import firebase from 'firebase';
import {useState, useEffect, useCallback, FC} from 'react';
import {Navbar} from '../navigasi/navigasiAtas/NavigasiAtas';
import NavigasiKanan from '../navigasi/navigasiKanan/NavigasiKanan';
import {BrowserRouter as Router, Switch, Route, Redirect, useLocation} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';
import {Beranda} from './beranda/Beranda'
import {Anime} from './anime/Anime'
import {Komik} from './komik/Komik'
import {KomponenPengguna} from './profilPengguna/Pengguna'
import {AniRedirect} from './Redirect'
import TransisiModal from '../komponen/cari/ModalCari';
import LoginPengguna from './loginPengguna/LoginPengguna';
import {ThemeProvider} from '@material-ui/core';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {IPengguna} from '../Data/Pengguna';
import useDimensiLayar, {TOrienLayar} from '../fungsi/UseDimensiLayar'
import LamanMusiman from './musiman/Musiman'
import NavigasiBawah from 'navigasi/NavigasiBawah'
import DaftarAnimePengguna from './DaftarAnimePengguna/src/DaftarAnimePengguna';
import TambahkanAnime from './TambahkanAnime';
import Forum from './Forum';
import ForumOrtu from './ForumOrtu';
import ForumAnak from './ForumAnak';
import EditAkun from './EditAkun/EditAkun';
import useToastOnline from 'fungsi/useToastOnline'
import TambahkanForum from './TambahkanForum/src/TambahkanForum';
import AktivitasPengguna from './AktivitasPengguna';
import Pemberitahuan from './Pemberitahuan';
import { storePengguna } from 'store/actions';
import { useDispatch } from 'react-redux';
import useCekTema from 'css/useCekTema';
import TambahkanFeeds from './TambahkanFeeds';
import 'react-loading-skeleton/dist/skeleton.css'
import { SkeletonTheme } from 'react-loading-skeleton';
import KomentarFeeds from './KomentarFeeds/KomentarFeeds';

const Website: FC<IWebsite> = (p)  =>  {
  const [telahLogin, setTelahLogin] = useState(false)
  const [pengguna, setPengguna] = useState<IPengguna | null>()
  const [dbPenggunaKosong, setDBPenggunaKosong] = useState(false)
  const {lebarLayar, tinggiLayar} = useDimensiLayar()
  const [orientasi, setOrientasi] = useState<TOrienLayar>('landscape')

  const dispatch = useDispatch()
  
  const [auth, _loadingAuth, _erorAuth] = useAuthState(firebase.auth())
  useToastOnline()

  useEffect(() => {
    dispatch(storePengguna(pengguna || null))
  }, [pengguna])
  
  useEffect(() => {
    const dbPenggunaDapat = auth?.uid ? firebase.firestore().doc('pengguna/' + auth.uid) : undefined
    const unsubscribe = dbPenggunaDapat?.onSnapshot(ss =>{
      if(ss.exists){
        const dokPengguna: any = ss.data()
        const penggunaDapat: IPengguna = dokPengguna
        setPengguna(penggunaDapat)
        setDBPenggunaKosong(false)

      }else if(!window.navigator.onLine){
        setDBPenggunaKosong(false)
      }else{
        setDBPenggunaKosong(true)
      }
    }, e => {
      setDBPenggunaKosong(false)
    })

    if(auth === null || auth === undefined){
      setTelahLogin(false)
      setPengguna(null)
    }else{
      setTelahLogin(true)
    }

    return () => {if(unsubscribe) unsubscribe()}
  }, [auth])


  useEffect(() => {
    if(lebarLayar > tinggiLayar){
      setOrientasi('landscape')
    }else{
      setOrientasi('portrait')
    }
  }, [lebarLayar, tinggiLayar])

  const [apakahCari, setCari] = useState(false);

  const handelCari = useCallback(() => {
    setCari(true);
  }, []);

  const handelCariTutup = useCallback(() => {
    setCari(false);
  }, []);

  const lokasiWeb = useLocation();

  const Tema = useCekTema()

  if(telahLogin && dbPenggunaKosong && !(lokasiWeb.pathname === '/h/login' || lokasiWeb.pathname === '/h/login/') ){
    return <Redirect to='/h/login'/>
  }else{  
    return (
      <ThemeProvider theme={Tema}>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <div className="App">
            <div className="App-header">
              <Navbar 
                pengguna={pengguna}
                handelCari={handelCari}
                lebarLayar={lebarLayar}
              />
            </div>
            {lebarLayar > 1000 ?
              <NavigasiKanan pengguna={pengguna}/>
              :
              <NavigasiBawah/>
            }
            <div className="web-utama-animemo">
              <section className="konten-website-animemo">
                <TransisiModal
                  handelCari={handelCari}
                  handelCariTutup={handelCariTutup}
                  apakahCari={apakahCari}
                />
                <Switch>
                  <Route path="/" exact component = {Beranda} />
                  <Route path="/instagram" exact render={(_p) => <AniRedirect lokasi='instagram'/>}/>
                  <Route path="/download" exact render={(_p) => <AniRedirect lokasi='download'/>}/>
                  <Route path="/iklanFB" exact render={(_p) => <AniRedirect lokasi='iklanFB'/>}/>
                  <Route path="/facebook" exact render={(_p) => <AniRedirect lokasi='facebook'/>}/>
                  <Route path="/a/:idAnime" component ={Anime} />
                  <Route path="/k/:idKomik" component = {Komik} />
                  <Route path="/p/:idPengguna" component = {KomponenPengguna} />
                  <Route path="/h/login" exact render={(p) => {return <LoginPengguna 
                    setDBPenggunaKosong={setDBPenggunaKosong}/>}}
                  />
                  <Route path="/h/musiman/:idMusiman" component={LamanMusiman}/>
                  <Route path="/h/daftar/:idDaftar" exact component={DaftarAnimePengguna} />
                  <Route path="/h/daftar/:idDaftar/:idPengguna" exact component={DaftarAnimePengguna} />
                  <Route path="/h/tambahkan-anime/:idAnime" component={TambahkanAnime} />
                  <Route path="/h/forum/:idOrtuForum/:slugTab" component={Forum} />
                  <Route path="/h/forum-ortu" component={ForumOrtu} />
                  <Route path="/h/forum-balasan/:idForum/:slugTab" component={ForumAnak} />
                  <Route path="/h/edit-akun" component={EditAkun} />
                  <Route path="/h/tambahkan-forum/:idOrtuForum" component={TambahkanForum} />
                  <Route path="/h/aktivitas/:idPengguna" component={AktivitasPengguna} />
                  <Route path="/h/pemberitahuan" component={Pemberitahuan} />
                  <Route path="/h/tambahkan-feeds" component={TambahkanFeeds} />
                  <Route path="/h/komentar-feeds/:idFeeds" component={KomentarFeeds} />
                </Switch>
              </section>
            </div>
          </div>
          <ToastContainer 
            position="bottom-center"
            autoClose={3500}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
          />
        </SkeletonTheme>
      </ThemeProvider>
    );
  }
}

export default Website

interface ILayerAuth{}


interface IWebsite {}