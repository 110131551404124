import firebase from 'firebase';

export function inisFirebase(){
    var firebaseConfig = {
        apiKey: "AIzaSyB5MKLbMqllhzFQ1ho4184I5LvxuwMG-tg",
        authDomain: "anime-saku-d729a.firebaseapp.com",
        databaseURL: "https://anime-saku-d729a.firebaseio.com",
        projectId: "anime-saku-d729a",
        storageBucket: "anime-saku-d729a.appspot.com",
        messagingSenderId: "105431119434",
        appId: "1:105431119434:web:cb4de0b59b7f860aa058b3",
        measurementId: "G-QCP7JK6H2K"
    };
    // Initialize Firebase
    if(!firebase.apps.length){
        firebase.initializeApp(firebaseConfig);
    }else{firebase.app()}
    firebase.analytics();
    firebase.firestore().enablePersistence().then((selesai) => {}).catch((err) => {})
}