import {createTheme} from '@material-ui/core/styles'
import { css } from "@emotion/react";

const Tema = createTheme({
  palette: {
    primary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
    },
    secondary:{
      main: '#0288d1',
      light: '#5eb8ff',
      dark: '#005b9f',
    },
    type: 'light',
  }
})

// Can be a string as well. Need to ensure each key-value pair ends with ;
export const CssLoading = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const Warna={
  primary: {
    light: '#ff7961',
    main: '#f44336',
    dark: '#ba000d',
  },
  secondary:{
    main: '#0288d1',
    light: '#5eb8ff',
    dark: '#005b9f',
  },
}




export default Tema