import {FC, useEffect, useState, useRef, useCallback, useMemo} from 'react';
import {useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import IkonTutup from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import TabMusiman from "./komponen/TabMusiman"
import "./Musiman.scss";
import { IDataAnime } from 'Data/Anime';
import DapatkanMusim from "fungsi/DapatkanMusim";
import { dapatkanAnime, dapatkanAnimeMusiman, useDalamLayarTab } from './komponen/Fungsi/Fungsi';
import firebase from 'firebase';
import LoadingLaman from 'laman/loadingLaman/LoadingLaman';
import useDimensiLayar from 'fungsi/UseDimensiLayar';
import InputRadio from 'komponen/input/inputRadio';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import useFetchFirestore from 'fungsi/useFetchFirestore';
import { AnimeModal } from 'laman/anime/AnimeModal';
import useKlikLuar from 'fungsi/useKlikLuar'

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const lokasiMusiman = {
  INI: 0,
  DEPAN: 1,
  LALU: 2,
  TOP: 3
}

const LamanMusiman: FC<ILamanMusiman> = (p) => {
  
  const tema = useTheme();
  const [lokasiTab, setLokasiTab] = useState(() => {
    let lokasi = lokasiMusiman.INI
    switch(p.match.params.idMusiman){
      case 'ini':
        lokasi = lokasiMusiman.INI;
        break;
      case 'depan':
        lokasi = lokasiMusiman.DEPAN;
        break;
      case 'lalu':
        lokasi = lokasiMusiman.LALU;
        break;
      case 'telusuri':
        lokasi = lokasiMusiman.TOP;
        break;
      default:
        lokasi = lokasiMusiman.INI
    }
    return lokasi
  });
  const sejarah = useHistory()
  const kueriDapat = useQuery()
  const refModal = useRef<HTMLDivElement | null>(null)
  

  const isiKueri = kueriDapat.get("kueri")
  const tahunKueri = kueriDapat.get("tahun")
  const musimKueri = kueriDapat.get("musim")
  const genreKueri = kueriDapat.get("genre")
  const jenisKueri = kueriDapat.get("jenis")
  const urutKueri = kueriDapat.get("urut")

  const mode = ['Skor teratas', 'Terpopuler', 'Tahun rilis', 'Genre', 'Jenis']
  const [modeTerpilih, setModeTerpilih] = useState<number | null>(null)
  const urutBerdasar = ['Terpopuler', 'Skor teratas']
  const [urutTerpilih, setUrutTerpilih] = useState<number | null>(null)
  const genre = ['Aksi', 'Drama', 'Fantasi', 'Komedi', 'Mecha', 'Romance', 'Shoujo', 'Shounen', 'Slice of Life', 
  'Anak-anak', 'Bela diri', 'Demensia', 'Ecchi', 'Game', 'Harem', 'Hentai', 'Horor', 'Iblis', 'Josei',
  'Kekuatan Super', 'Luar Angkasa', 'Militer', 'Mobil', 'Musik', 'Olahraga', 'Parodi', 'Petualangan', 'Polisi', 'Psikologis',
  'Samurai', 'Sci-Fi', 'Seinen', 'Sejarah' , 'Sekolah', 'Shoujo Ai', 'Shounen Ai', 'Sihir', 'Supernatural', 'Thriler', 'Vampir',
  'Yaoi', 'Yuri']
  const [genreTerpilih, setGenreTerpilih] = useState<number | null>(null);
  const jenis = ['TV', 'Spesial', 'OVA', 'ONA', 'Film'];
  const [jenisTerpilih, setJenisTerpilih] = useState<number | null>(null);
  const musimString = ['Dingin', 'Semi', 'Panas', 'Gugur']
  const [musimTerpilih, setMusimTerpilih] = useState<number | null>(null);
  const [arTahun, setArTahun ] = useState<string[]>([])
  const [tahunTerpilih, setTahunTerpilih] = useState<number | null>(null);
  const [idAnimeTop, setIdAnimeTop] = useState<string | null>(null)

  useKlikLuar(refModal, () => {setIdAnimeTop(null)})

  const handelBerubah = (event: React.ChangeEvent<{}>, newValue: number) => {
    setLokasiTab(newValue);
    let tabDapat = 'ini'
    switch(newValue){
      case lokasiMusiman.INI:
        tabDapat = 'ini';
        break;
      case lokasiMusiman.DEPAN:
        tabDapat = 'depan';
        break;
      case lokasiMusiman.LALU:
        tabDapat = 'lalu';
        break;
      case lokasiMusiman.TOP:
        tabDapat = 'telusuri'
        break;
    }
    sejarah.push('/h/musiman/' + tabDapat )
  };

  const handelIndeksBerubah = (index: number) => {
    setLokasiTab(index);
  };

  //dapatkan tanggal musiman{
  const tanggalMusimIni = new Date()
  const tanggalMusimLalu = new Date()
  const tanggalMusimDepan = new Date()
  tanggalMusimLalu.setDate(tanggalMusimLalu.getDate() - 90)
  tanggalMusimDepan.setDate(tanggalMusimDepan.getDate() + 90)
  const tahunMusimIni = DapatkanMusim(tanggalMusimIni)
  const tahunMusimDepan = DapatkanMusim(tanggalMusimDepan)
  const tahunMusimLalu = DapatkanMusim(tanggalMusimLalu)

  const [animeMusimIni, 
    refMusimIniTerakhir, 
    indeksMusimIniTerakhir, 
    loadingMusimIni, 
    dokMusimIniTerakhirDlmLyr, 
    cacheMusimIni] =
  useFetchFirestore<IDataAnime>(
    10, 
    (firebase.firestore().collection("databaseAnime").where("musimAnime", "==", tahunMusimIni)
      .orderBy("jumlahReview", "desc")
      .orderBy("memberMal", "desc")), 
    'musimIni',
    lokasiTab,
    undefined,
    500,
    lokasiMusiman.INI
    )

  const [animeMusimDepan, 
    refMusimDepanTerakhir, 
    indeksMusimDepanTerakhir, 
    loadingMusimDepan, 
    dokMusimDepanTerakhirDlmLyr, 
    cacheMusimDepan] =
  useFetchFirestore<IDataAnime>(
    10, 
    (firebase.firestore().collection("databaseAnime").where("musimAnime", "==", tahunMusimDepan)
      .orderBy("jumlahReview", "desc")
      .orderBy("memberMal", "desc")), 
    'musimDepan',
    lokasiTab,
    undefined,
    500,
    lokasiMusiman.DEPAN
    )

  const [animeMusimLalu, 
    refMusimLaluTerakhir, 
    indeksMusimLaluTerakhir, 
    loadingMusimLalu, 
    dokMusimLaluTerakhirDlmLyr, 
    cacheMusimLalu] =
  useFetchFirestore<IDataAnime>(
    10, 
    (firebase.firestore().collection("databaseAnime").where("musimAnime", "==", tahunMusimLalu)
      .orderBy("jumlahReview", "desc")
      .orderBy("memberMal", "desc")), 
    'musimLalu',
    lokasiTab,
    undefined,
    500,
    lokasiMusiman.LALU
    )

  // data anime musiman {
  const [animeTop, setAnimeTop] = useState<Array<IDataAnime>>([])

  // logika UI{
  const [topTerakhirLoad, setTopTerakhirLoad] = useState(-1);
  const [dokumenTerakhirTop, setDokumenTerakhirTop] = 
  useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null>(null);
  const [akhirDataTop, setAkhirDataTop] = useState(false);
  const [loadingTop, setLoadingTop] = useState(false);

  // ref anime terakhir musiman{
  const refAnimeTerakhirTop = useRef<HTMLLIElement>(null)
  // }ref anime terakhir musiman

  // anime musiman terlihat{
  const animeTerakhirTopTerlihat = useDalamLayarTab(refAnimeTerakhirTop, lokasiTab === 3)

  // dapatkan tahun hingga 40 tahun ke belakang{
  useEffect(() => {
    setArTahun([])
    if(arTahun.length === 0){
      for(let i = 0; i < 40; i++){
        arTahun.push(((new Date()).getFullYear() - i).toString())
      }
    }
    setArTahun(arTahun)
  }, [])

  // } logika UI

  // dapatkan data musiman{

  useEffect(() => {
    let lokasi = lokasiMusiman.INI
    switch(p.match.params.idMusiman){
      case 'ini':
        lokasi = lokasiMusiman.INI;
        break;
      case 'depan':
        lokasi = lokasiMusiman.DEPAN;
        break;
      case 'lalu':
        lokasi = lokasiMusiman.LALU;
        break;
      case 'telusuri':
        lokasi = lokasiMusiman.TOP;
        break;
      default:
        lokasi = lokasiMusiman.INI
    }
    setLokasiTab(lokasi)
  }, [p.match.params.idMusiman])

  useEffect(() => {
    if(animeTerakhirTopTerlihat){
      dapatkanAnime(
        modeTerpilih,
        genre[genreTerpilih ?? 0] ?? null,
        urutTerpilih,
        musimString[musimTerpilih ?? 0] ?? null,
        Number(arTahun[tahunTerpilih ?? 0] ?? "1970"),
        jenis[jenisTerpilih ?? 0] ?? null,
        10,
        setTopTerakhirLoad,
        animeTop,
        setAnimeTop,
        setAkhirDataTop,
        akhirDataTop,
        setLoadingTop,
        setDokumenTerakhirTop,
        dokumenTerakhirTop,
      )
    }

    // console.log("anime terakhir tak terlihat")
  }, [animeTerakhirTopTerlihat])

  useEffect(() => {
    sejarah.listen((loc, act) => {if(act ==='POP'){
      setIdAnimeTop(null)
    }})
    
  }, [])

  useEffect(() => {
    if(isiKueri){
      const isiKueriType = isiKueri as TKueri
      switch (isiKueriType){
        case "skor":
          setModeTerpilih(0);
          break;
        case "terpopuler":
          setModeTerpilih(1);
          break;
        case "tahun":
          setModeTerpilih(2);
          if(arTahun.length !== 0 && tahunKueri){
            for(let i = 0; i < arTahun.length; i++){
              if(Number(tahunKueri) !== NaN && Number(arTahun[i]) !== NaN 
                && Number(tahunKueri) === Number(arTahun[i])){
                  setTahunTerpilih(i)
              }
            }
          };
          if(Number(musimKueri) !== NaN){
            setMusimTerpilih(Number(musimKueri))
          }
          break;
        case "genre":
          setModeTerpilih(3);
          if(Number(genreKueri) !== NaN){
            setGenreTerpilih(Number(genreKueri))
          }
          if(Number(urutKueri) !== NaN){
            setUrutTerpilih(Number(urutKueri))
          };
          break;
        case "jenis":
          setModeTerpilih(4);
          if(Number(jenisKueri) !== NaN){
            setJenisTerpilih(Number(jenisKueri))
          }
          if(Number(urutKueri) !== NaN){
            setUrutTerpilih(Number(urutKueri))
          };
          if(Number())
          break;
      }
    }
  }, [isiKueri,
    tahunKueri,
    musimKueri,
    genreKueri,
    jenisKueri,
    urutKueri,
    arTahun
  ])

  const cacheAnimeTop = useCallback(() => { 
    if(animeTop && dokumenTerakhirTop 
      && (topTerakhirLoad !== null || topTerakhirLoad !== undefined) ){
      let namaLamanTop = ""
      if(modeTerpilih){namaLamanTop = mode[modeTerpilih]}
      let kueri: TKueri = "tahun"
      if(modeTerpilih === 2 && tahunTerpilih !== null && musimTerpilih !== null){
        const tahunDapatNama = arTahun[tahunTerpilih] || "1970"
        const musimDapatNama = musimString[musimTerpilih]
        namaLamanTop = namaLamanTop + tahunDapatNama + musimDapatNama
        namaLamanTop.split(" ").join("-")
        kueri = "tahun"
        sejarah.push('/h/musiman/telusuri' +`?kueri=${kueri}&tahun=${tahunDapatNama}&musim=${musimTerpilih}`)
      }else if(modeTerpilih === 3 && genreTerpilih !== null && urutTerpilih !== null){
        const genreDapat = genre[genreTerpilih]
        const urutDapat = urutBerdasar[urutTerpilih]
        namaLamanTop = namaLamanTop + genreDapat + urutDapat
        namaLamanTop.split(" ").join("-")
        kueri = "genre"
        sejarah.push('/h/musiman/telusuri' +`?kueri=${kueri}&genre=${genreTerpilih}&urut=${urutTerpilih}`)
      }else if(modeTerpilih === 4 && jenisTerpilih !== null && urutTerpilih !== null){
        const jenisDapat = jenis[jenisTerpilih]
        const urutDapat = urutBerdasar[urutTerpilih]
        namaLamanTop = namaLamanTop + jenisDapat + urutDapat
        namaLamanTop.split(" ").join("-")
        kueri = "jenis"
        sejarah.push('/h/musiman/telusuri' +`?kueri=jenis&jenis=${jenisTerpilih}&urut=${urutTerpilih}`)
      }else if(modeTerpilih === 0 ){
        kueri = "skor"
        sejarah.push('/h/musiman/telusuri' +`?kueri=${kueri}`)
      }else if(modeTerpilih === 1){
        kueri = "terpopuler"
        sejarah.push('/h/musiman/telusuri' +`?kueri=${kueri}`)
      }
      namaLamanTop = namaLamanTop.split(" ").join("-")
      // const namaLaman = namaLamanTop
      // if(namaLaman){
      //   store.dispatch(cacheLaman(namaLaman, cacheLamanData, window.scrollY))
      // }
    }
  }, [animeTop, 
    dokumenTerakhirTop, 
    akhirDataTop, 
    topTerakhirLoad, 
    refAnimeTerakhirTop,
    modeTerpilih,
    arTahun,
    tahunTerpilih,
    musimTerpilih,
    genreTerpilih,
    urutTerpilih,
    jenisTerpilih
  ])

  useEffect(() => {
    cacheAnimeTop()
  }, [cacheAnimeTop])


  useEffect(() => {
    setAkhirDataTop(false)
    console.log("namaLaman")
    if(lokasiTab === 3 ){
      dapatkanAnime(
        modeTerpilih,
        genre[genreTerpilih || 0] || null,
        urutTerpilih,
        musimString[musimTerpilih || 0] || null,
        Number(arTahun[tahunTerpilih || 0] || "1970"),
        jenis[jenisTerpilih || 0] || null,
        10,
        setTopTerakhirLoad,
        animeTop,
        setAnimeTop,
        setAkhirDataTop,
        akhirDataTop,
        setLoadingTop,
        setDokumenTerakhirTop
      )
    }
  }, [lokasiTab
    , modeTerpilih
    , urutTerpilih
    , genreTerpilih
    , jenisTerpilih
    , musimTerpilih
    , arTahun
    , tahunTerpilih])


  const {lebarLayar, tinggiLayar} = useDimensiLayar()
  useEffect(() => {console.log("mode " + modeTerpilih)}, [modeTerpilih])
  return(
    <>
      <section className='kontainer-anime-musiman-apl-animemo' style={idAnimeTop !== null ? {} : {display: "none"}}>
        <button className='tbl-tutup'>
          <IkonTutup />
        </button>
        <div className='isi-anime-musiman-apl-animemo' ref={refModal}>
          <AnimeModal idAnime={idAnimeTop}/>
        </div>
      </section>
      <section className='konten-musiman-laman'>
        <AppBar position="static" color="default">
          <Tabs
            value={lokasiTab}
            onChange={handelBerubah}
            indicatorColor="primary"
            style={{backgroundColor: 'var(--bg)'}}
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Musim ini"  style={{fontSize: lebarLayar > 300 ? "14px" : "10px", backgroundColor: "var(--bg)"}}/>
            <Tab label="Musim depan" style={{fontSize: lebarLayar > 300 ? "14px" : "10px", backgroundColor: "var(--bg)"}} />
            <Tab label="Musim lalu" style={{fontSize: lebarLayar > 300 ? "14px" : "10px", backgroundColor: "var(--bg)"}}/>
            <Tab label="Telusuri" style={{fontSize: lebarLayar > 300 ? "14px" : "10px", backgroundColor: "var(--bg)"}}/>
          </Tabs>
        </AppBar>
        <TabPanel value={lokasiTab} index={lokasiMusiman.INI} dir={tema.direction} >
          <div className='tab-tab-musim'>
            <TabMusiman 
              animeMusiman={animeMusimIni} 
              animeTerakhirLoad={indeksMusimIniTerakhir}
              refAnimeTerakhir={refMusimIniTerakhir}
              cacheLaman={cacheMusimIni}
            />
            <div className="loading">
              {loadingMusimIni && <LoadingLaman />}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={lokasiTab} index={lokasiMusiman.DEPAN} dir={tema.direction}>
          <div className='tab-tab-musim'>
            <TabMusiman animeMusiman={animeMusimDepan}
              animeTerakhirLoad={indeksMusimDepanTerakhir}
              refAnimeTerakhir={refMusimDepanTerakhir}
              cacheLaman={cacheMusimDepan}
            />
            <div className="loading">
              {loadingMusimDepan && <LoadingLaman />}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={lokasiTab} index={lokasiMusiman.LALU} dir={tema.direction}>
          <div className='tab-tab-musim'>
            <TabMusiman animeMusiman={animeMusimLalu}
              animeTerakhirLoad={indeksMusimLaluTerakhir}
              refAnimeTerakhir={refMusimLaluTerakhir}
              cacheLaman={cacheMusimLalu}
            />
            <div className="loading">
              {loadingMusimLalu && <LoadingLaman />}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={lokasiTab} index={lokasiMusiman.TOP} dir={tema.direction}>
          <div className='tab-tab-musim'>
            <div className='pengaturan-urutan'>
              <InputRadio 
                judulPilihan={'Judul pilihan'}
                isiPilihan={mode} 
                pilihanTerpilih={modeTerpilih} 
                onTerpilih={(nilai) => {setMusimTerpilih(null)
                  ; setUrutTerpilih(null); setTahunTerpilih(null); setGenreTerpilih(null)
                  ; setJenisTerpilih(null); setModeTerpilih(nilai)}}
                pilihanKosongString={'Pilih Mode'}
                styleTeks={{fontSize: lebarLayar < 400 ? '12px' : '15px'}}
              />
              {!(modeTerpilih === null || modeTerpilih === 0 || modeTerpilih === 1) && 
                <div className="urutan">
                  <div className='isi'>
                    <InputRadio 
                      isiPilihan={modeTerpilih === 2 ? musimString
                        : modeTerpilih === 3 || modeTerpilih === 4 ? urutBerdasar
                        : []
                      } 
                      judulPilihan={modeTerpilih === 2 ? 'Pilih Musim' 
                        : modeTerpilih === 3 || modeTerpilih === 4 ? 'Urut Berdasar' 
                        : ''
                      }
                      pilihanTerpilih={modeTerpilih === 2 ? musimTerpilih 
                        : modeTerpilih === 3 || modeTerpilih === 4 ? urutTerpilih
                        : null
                      } 
                      onTerpilih={(nilai) => {modeTerpilih === 2 ? setMusimTerpilih(nilai) 
                        : modeTerpilih === 3 || modeTerpilih === 4 ? setUrutTerpilih(nilai)
                        : setUrutTerpilih(nilai)}
                      }
                      pilihanKosongString={modeTerpilih === 2 ? 'Pilih Musim' 
                        : modeTerpilih === 3 || modeTerpilih === 4 ? 'Urut Berdasar'
                        : ''
                      }
                      styleTeks={{fontSize: lebarLayar < 400 ? '12px' : '15px'}}
                    />
                  </div>
                  <div className='isi'>
                    <InputRadio 
                      isiPilihan={modeTerpilih === 2 ? arTahun 
                        : modeTerpilih === 3 ? genre 
                        : modeTerpilih === 4 ? jenis
                        : []
                      } 
                      judulPilihan={modeTerpilih === 2 ? 'Pilih Tahun' 
                        : modeTerpilih === 3 ? 'Pilih Genre'
                        : modeTerpilih === 4 ? 'Pilih Jenis'
                        : ''
                      }
                      pilihanTerpilih={modeTerpilih === 2 ? tahunTerpilih 
                        : modeTerpilih === 3 ? genreTerpilih
                        : modeTerpilih === 4 ? jenisTerpilih
                        : null
                      } 
                      onTerpilih={(nilai) => {modeTerpilih === 2 ? setTahunTerpilih(nilai) 
                        : modeTerpilih === 3 ? setGenreTerpilih(nilai)
                        : modeTerpilih === 4 ? setJenisTerpilih(nilai)
                        : setJenisTerpilih(nilai)
                      }}
                      pilihanKosongString={modeTerpilih === 2 ? 'Pilih Tahun' 
                        : modeTerpilih === 3 ? 'Pilih Genre'
                        : modeTerpilih === 4 ? 'Pilih Jenis'
                        : ''
                      }
                      styleTeks={{fontSize: lebarLayar < 400 ? '12px' : '15px'}}
                    />
                  </div>
                </div>
              }
            </div>
            <TabMusiman animeMusiman={animeTop}
              animeTerakhirLoad={topTerakhirLoad}
              refAnimeTerakhir={refAnimeTerakhirTop}
              bukaDgnModal
              setIdAnimeModal={setIdAnimeTop}
            />
            <div className="loading">
              {loadingTop && <LoadingLaman />}
            </div>
          </div>
        </TabPanel>
      </section>
    </>
  )
}



const TabPanel: FC<ITabPanel> = (p) => {
  const { children, value, index } = p;
  const { lebarLayar, tinggiLayar } = useDimensiLayar()
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <Box p={3} style={lebarLayar < 600 ? {padding: 0, paddingTop: "5px"} : {padding: "20px"}}>
          <Typography className="tab-panel">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default LamanMusiman

interface ILamanMusiman extends RouteComponentProps<{idMusiman: "ini" | "lalu" | "depan" | "telusuri"}>  {

}

type TKueri = 'skor' | 'terpopuler' | 'tahun' | 'genre' | 'jenis'  

interface ITabPanel {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}
